import { graphql, Link, PageProps } from 'gatsby';
import * as React from 'react';

import Shell from '../components/_layout/shell';
import { InView } from '../components/inView';
import { Ticker } from '../components/ticker';

import LOGO from '../assets/AO_LOGO_ORANGE.svg';

import * as styles from './index.module.scss';

const Index: React.FC<PageProps<Queries.HomePageQuery>> = ({ data }) => {
  const homePageData = data.prismicHomePage?.data;

  return (
    <Shell>
      <div className={styles.container}>
        <div className={styles.header}>
          <nav className={styles.nav}>
            <InView delay={600}>
              <Link to="/" activeClassName={styles.active}>
                Home
              </Link>
            </InView>
            <InView delay={650}>
              <Link to="/work" activeClassName={styles.active}>
                Work
              </Link>
            </InView>
            <InView delay={700}>
              <Link to="/go-all-out" activeClassName={styles.active}>
                Go AllOut
              </Link>
            </InView>
          </nav>

          <InView className={styles.content} delay={900}>
            <p>{homePageData?.description?.text || ''}</p>
            <div className={styles.contact}>
              <a href={`mailto:${homePageData?.email}`}>{homePageData?.email}</a>
              <a href={`tel:${homePageData?.phone_number}`}>{homePageData?.phone_number_display}</a>
            </div>
          </InView>
        </div>

        <div className={styles.hero}>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={LOGO} />
          </div>
        </div>
        <Ticker tickerText={homePageData?.banner_content?.richText} />
      </div>
    </Shell>
  );
};

export const pageQuery = graphql`
  query HomePage {
    prismicHomePage {
      data {
        description {
          text
        }
        email
        phone_number
        phone_number_display
        banner_content {
          richText
        }
      }
    }
  }
`;

export default Index;
